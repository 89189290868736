// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-terms .container {
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
}
.privacy-terms .container .fs-b {
  color: rgba(255, 255, 255, 0.6666666667) !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/privacyterms.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,iBAAA;AAAR;AACQ;EACI,mDAAA;AACZ","sourcesContent":[".privacy-terms {\n    .container {\n        padding: 100px;\n        display: flex;\n        flex-direction: column;\n        justify-content: flex-start;\n        margin-top: 100px;\n        .fs-b{\n            color: #ffffffaa !important;\n        }\n    };\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
