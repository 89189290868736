// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/left-arrow.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/images/right-arrow.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination-bullet{
  background: linear-gradient(0deg, #817d7d, #817d7d), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  width: 10px;
  height: 10px;
  border: 1px;
}
.swiper-pagination-bullet-active{
  background: linear-gradient(90deg, #71EA9F 0%, #A16FF6 100%);
  width: 30px;
  height: 10px;
  border-radius: 100px;

}
.swiper-pagination {
  position: relative;
  margin-top: 30px;
  text-align: left;
}

.swiper-button-prev{
  right: 60px;
  bottom: 0px;
  left: unset;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  color: transparent;
  top: unset;
  z-index: 100;
}

.swiper-button-next{
  right: 0px;
  bottom: 0px;
  left: unset;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  color: transparent;
  top: unset;
  z-index: 100;
}

`, "",{"version":3,"sources":["webpack://./src/styles/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;EACE,kBAAkB;EAClB,eAAe;;EAEf,iCAAiC;EACjC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,8HAA8H;EAC9H,WAAW;EACX,YAAY;EACZ,WAAW;AACb;AACA;EACE,4DAA4D;EAC5D,WAAW;EACX,YAAY;EACZ,oBAAoB;;AAEtB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,WAAW;EACX,yDAAwD;EACxD,4BAA4B;EAC5B,wBAAwB;EACxB,2BAA2B;EAC3B,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;EACX,WAAW;EACX,yDAAyD;EACzD,4BAA4B;EAC5B,wBAAwB;EACxB,2BAA2B;EAC3B,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd","sourcesContent":[".swiper {\n    width: 100%;\n    height: 100%;\n}\n\n.swiper-slide {\n  text-align: center;\n  font-size: 18px;\n\n  /* Center slide text vertically */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.swiper-pagination-bullet{\n  background: linear-gradient(0deg, #817d7d, #817d7d), linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));\n  width: 10px;\n  height: 10px;\n  border: 1px;\n}\n.swiper-pagination-bullet-active{\n  background: linear-gradient(90deg, #71EA9F 0%, #A16FF6 100%);\n  width: 30px;\n  height: 10px;\n  border-radius: 100px;\n\n}\n.swiper-pagination {\n  position: relative;\n  margin-top: 30px;\n  text-align: left;\n}\n\n.swiper-button-prev{\n  right: 60px;\n  bottom: 0px;\n  left: unset;\n  background-image: url(\"../assets/images/left-arrow.svg\");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n  color: transparent;\n  top: unset;\n  z-index: 100;\n}\n\n.swiper-button-next{\n  right: 0px;\n  bottom: 0px;\n  left: unset;\n  background-image: url(\"../assets/images/right-arrow.svg\");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n  color: transparent;\n  top: unset;\n  z-index: 100;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
