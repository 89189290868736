// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/404/404Bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page-section {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}), lightgray -0.408px -7px/100.054% 100.705% no-repeat;
  background-size: cover;
  padding: 205px 16px 70px;
  display: flex;
  justify-content: center;
}
.error-page-section img {
  width: 100%;
}
.error-page-section .error-message {
  color: #7B7F94;
  text-align: center;
  padding: 24px 0;
}
.error-page-section .back-to-home {
  display: flex;
  justify-content: center;
}
.error-page-section .back-to-home button {
  padding: 16px 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/pages/404.scss"],"names":[],"mappings":"AAAA;EACI,wGAAA;EACA,sBAAA;EACA,wBAAA;EACA,aAAA;EACA,uBAAA;AACJ;AACI;EACI,WAAA;AACR;AAEI;EACI,cAAA;EACA,kBAAA;EACA,eAAA;AAAR;AAEI;EACI,aAAA;EACA,uBAAA;AAAR;AACQ;EACI,kBAAA;AACZ","sourcesContent":[".error-page-section {\n    background: url(\"../../assets/images/404/404Bg.png\"), lightgray -0.408px -7px / 100.054% 100.705% no-repeat;\n    background-size: cover;\n    padding: 205px 16px 70px;\n    display: flex;\n    justify-content: center;\n\n    img {\n        width: 100%;\n    }\n\n    .error-message {\n        color: #7B7F94;\n        text-align: center;\n        padding: 24px 0;\n    }\n    .back-to-home {\n        display: flex;\n        justify-content: center;\n        button {\n            padding: 16px 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
